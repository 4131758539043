<template>
    <ul :class="className">
        <li
                v-for="(item, index) in list"
                :class="[item.extraClass, {'active': active === index}]"
                :key="index"
        >
            <router-link :to="item.url" :class="[item.linkClass]">
                {{ item.text }}
            </router-link>
<!--            <span class="sub-toggle" v-if="item.subMenu" @click="openSubItem(index)">-->
<!--                <i class="fa fa-chevron-down"></i>-->
<!--            </span>-->
                <ul v-if="item.subMenu" :class="item.subClass">
                    <li v-for="(val, idx) in item.subMenu" :key="idx" :class="{'active': subActive === idx}">
                        <router-link :to="val.url" :class="[val.linkClass]">
                            {{ val.text }}
                        </router-link>
                    </li>
                </ul>
        </li>
    </ul>
</template>

<script>

    export default {
        name: 'module-main-menu',
        components: {},
        props: {
            list: {
                type: Array,
                default: () => []
            },
            className: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                isOpen: true,
                active: null,
                subActive: null
            }
        },
        methods: {
            openSubItem(index) {
                if (index === this.active) {
                    this.active = null;
                } else {
                    this.active = index;
                }
                this.isOpen = !this.isOpen;
            },
            openSubChild(index) {
                if (index === this.subActive) {
                    this.subActive = null;
                } else {
                    this.subActive = index;
                }
            }
        }
    }
</script>
