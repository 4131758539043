import HomePage from "./components/page/HomePage";
import {createRouter, createWebHistory} from "vue-router";
import InfoPage from "./components/page/InfoPage";
import Products from "./components/page/Products";
import Product from "./components/page/Product";
import store from "./store";
import ShoppingChart from "./components/page/ShoppingChart";
import Login from "./components/page/Login";
import Register from "./components/page/Register";
import ContactUs from "./components/page/ContactUs";
import Profile from "./components/page/Profile";
import {getMe} from "./repositories/UserRepository";
import cookie from "js-cookie";
import OrderPage from "./components/page/OrderPage";
import Payment from "./components/page/Payment";
import ProfileHome from "./components/page/ProfileHome";
import MyOrdersPage from "./components/page/MyOrdersPage";
import NotFoundPage from "./components/page/NotFoundPage";
import MyFavorites from "./components/page/MyFavorites";
import MyViewHistory from "./components/page/MyViewHistory";
import MyAddresses from "./components/page/MyAddresses";
import Forget from "@/components/page/Forget.vue";
import Reset from "@/components/page/Reset.vue";
import Password from "@/components/page/Password.vue";
import BlogPostPage from "@/components/page/BlogPostPage.vue";
import BlogListPage from "@/components/page/BlogListPage.vue";


const router = createRouter({
        history: createWebHistory(),
        // eslint-disable-next-line no-unused-vars
        scrollBehavior(to, from, savedPosition) {
            return {
                left: 0, top: 0,
                behavior: 'smooth'
            }
        },
        routes: [
            {'path': '/', component: HomePage},
            {'path': '/page/:slug', component: InfoPage},
            {'path': '/blog', name:'blogList', component: BlogListPage},
            {'path': '/blog/:slug', name:'blog', component: BlogPostPage},
            {
                'name': 'category',
                'path': '/category/:slug', component: Products, props: route => ({
                    pageType: "category",
                    categorySlug: route.params.slug
                })
            }, {
                'path': '/product/:slug', component: Product, props: route => ({
                    default: true,
                    productSlug: route.params.slug
                })
            },
            {'path': '/contact', component: ContactUs},
            {'path': '/branch', component: HomePage},
            {
                'name': 'products',
                'path': '/products', component: Products, props: {
                    pageType: "products",
                    categorySlug: ''
                }
            },
            {'path': '/cart', component: ShoppingChart},
            {
                'path': '/login', component: Login, beforeEnter: (to, from, next) => {
                    if (store.state.auth.token !== null && store.state.auth.token !== "null") {
                        next('/profile');
                    } else {
                        next();
                    }
                }
            },
            {
                'path': '/forget', component: Forget, beforeEnter: (to, from, next) => {
                    if (store.state.auth.token !== null && store.state.auth.token !== "null") {
                        next('/profile');
                    } else {
                        next();
                    }
                }
            },
            {
                'path': '/reset', component: Reset
            },
            {
                'path': '/register', component: Register, beforeEnter: (to, from, next) => {
                    if (store.state.auth.token !== null && store.state.auth.token !== "null") {
                        next('/profile');
                    } else {
                        next();
                    }
                }
            },
            {
                'path': '/profile', component: Profile, beforeEnter: async (to, from, next) => {
                    if (store.state.auth.token !== null && store.state.auth.token !== "null") {
                        await getMe(store.state.auth.token).then(data => {
                            if (data.code === 200) {
                                next();
                            } else if (data.code === 401) {
                                cookie.set('token', null);
                                next('/login');
                            } else {
                                next('/');
                            }

                        })

                    } else {
                        next('/login');
                    }
                }, children: [
                    {
                        'path': '', component: ProfileHome
                    },
                    {
                        'path': 'orders', component: MyOrdersPage
                    },
                    {
                        'path': 'order/:id', component: OrderPage
                    },
                    {
                        'path': 'favorites', component: MyFavorites
                    },
                    {
                        'path': 'addresses', component: MyAddresses
                    },
                    {
                        'path': 'viewHistory', component: MyViewHistory
                    },

                    {
                        'path': 'password', component: Password
                    },
                ]
            },

            {
                'path': '/payment/:id', component: Payment
            },
            {
                path: "/notFound", component: NotFoundPage
            },

            {
                path: "/:catchAll(.*)", component: NotFoundPage
            }

        ]
    }
);

router.beforeEach(() => {
    store.commit('app/setAppDrawer', false);

    window.fbq('track', 'PageView');
})

export default router;