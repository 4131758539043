<template>
    <div class="ps-product ps-product--wishlist" v-if="product">
        <div class="ps-product__remove"><a href="#" @click.prevent="handleRemove()"><i class="icon-cross"></i></a></div>
        <div class="ps-product__thumbnail">
            <router-link class="ps-product__image" :to="`/product/${product.slug}`">
                <figure>
                    <img :src="baseDomain + product.thumbnail.url" alt="alt"/>
                    <img :src="baseDomain + product.thumbnail_back.url" alt="alt"/>
                </figure>
            </router-link>
        </div>
        <div class="ps-product__content">
            <h5 class="ps-product__title">
                <router-link :to="`/product/${product.slug}`">{{ product.name }}</router-link>
            </h5>
            <div class="ps-product__row">
                <div class="ps-product__label">Qiymət:</div>
                <div class="ps-product__value">
                    <span
                            :class="[
                            'ps-product__price',
                            product.sale_price ? 'sale' : ''
                        ]"
                    > {{
                            product.sale_price ? product.sale_price : product.price
                        }} AZN</span
                    >
                    <span class="ps-product__del" v-if="product.sale_price"
                    >{{ product.price }} AZN</span
                    >
                </div>
            </div>
            <div class="ps-product__row ps-product__stock">
                <div class="ps-product__label">Stok:</div>
                <div class="ps-product__value">
                    <span v-if="product.is_stock" class="ps-product__in-stock">ANBARDA VAR</span>
                    <span v-else class="ps-product__out-stock">Anbarda yoxdur</span>
                </div>
            </div>
            <div class="ps-product__cart">
                <button class="ps-btn" @click="addToCart()">Səbətə əlavə et</button>
            </div>
            <div class="ps-product__row ps-product__quantity">
                <div class="ps-product__label">Miqdar:</div>
                <div class="ps-product__value">{{ quantity }}</div>
            </div>
            <div class="ps-product__row ps-product__subtotal">
                <div class="ps-product__label">Cəmi:</div>
                <div class="ps-product__value">{{ productTotal() }} AZN</div>
            </div>
        </div>
    </div>
</template>

<script>

    import {baseUrl} from "../../repositories/Repository";

    export default {
        props: {
            product: {
                type: Object
            },
            type: {
                type: String,
                default: 'wishlist'
            },
            quantity: {
                type: Number,
                default: 1
            }
        },
        components: {},
        data() {
            return {
                baseDomain: baseUrl,
            };
        },
        methods: {
            handleRemove() {
                if (this.type === 'cart') {
                    this.$store.dispatch("cart/removeProductCart", this.product.id);
                }
            },
            productPrice() {
                if (this.product) {
                    return this.product.sale_price
                        ? this.product.sale_price
                        : this.product.price;
                }
                return 0;
            },
            productTotal() {
                let total = this.productPrice() * this.quantity;
                return total.toFixed(2);
            },
            addToCart() {
                const params = {
                    productId: this.product.id,
                    optionId: null,
                    count: this.quantity
                };
                this.$store.dispatch('cart/addProductToCart', params);
                this.$store.commit('cart/setDialogAddCart', true);
                this.$store.commit('cart/setProductOverview', this.product);
                this.$store.commit('compare/setDialogCompare', false);
                this.$store.commit('cart/setDialogQuickview', false);
                this.$store.commit('product/addCartProduct', this.product);
                this.$store.commit('cart/setDialogAddCart2', false);
            }
        }
    }
</script>
