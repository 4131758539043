<template>
  <div class="container mt-5">
    <h2 class="ps-blog__title mb-4">Blog</h2>
    <div class="ps-blog__content">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4" v-for="b in blogs.slice(0,count)" :key="b.id">


          <BlogPost :blog="b"/>
        </div>
      </div>

      <div class="text-center mt-4" v-if="buttonVisible">
        <router-link class="ps-btn ps-btn--primary" to="/blog">daha çox</router-link>
      </div>

    </div>
  </div>

</template>

<script>
import {baseUrl, imagePath, imageWidePath} from "@/repositories/Repository";
import {getBlogs} from "@/repositories/GeneralDataRepository";
import BlogPost from "@/components/layout/BlogPost.vue";

export default {
  name: "BlogListView",
  components: {BlogPost},
  props: {
    count: {
      type: Number,
      default: () => {
        return 10;
      }
    },
    buttonVisible: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  data() {
    return {
      blogs: [],

      baseDomain: baseUrl,
      imagePath: imagePath,
      imageWidePath: imageWidePath,
    }
  },
  methods: {
    async getBlogs() {

      await getBlogs().then(data => {
        this.blogs = data;
      })

    },

  },
  created() {
    this.getBlogs();
  },
}
</script>

<style scoped>

.ps-blog__title {
  color: #103178;
  text-align: center;
}
</style>