<template>
  <div>
    <div class="ps-account">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="ps-form--review">
              <h2 class="ps-form__title">Şifrəmi dəyiş</h2>

              <form @submit.prevent="handleLogin()">
                <div class="ps-form__group">
                  <label for="registerPassword" class="ps-form__label">Şifrə *</label>
                  <input
                      class="form-control ps-form__input p-3"
                      v-model="registerPassword"
                      id="registerPassword"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      required
                      @input="v$.registerPassword.$touch()"
                      @blur="v$.registerPassword.$touch()"
                  />

                  <div class="text-danger" v-for="e in registerPasswordErrors" :key="e">{{ e }}</div>
                </div>
                <div class="ps-form__group">
                  <label for="registerPasswordRepeat" class="ps-form__label">Şifrə təkrar *</label>
                  <input
                      class="form-control ps-form__input p-3"
                      v-model="registerPasswordRepeat"
                      id="registerPasswordRepeat"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      required
                      @input="v$.registerPasswordRepeat.$touch()"
                      @blur="v$.registerPasswordRepeat.$touch()"
                  />

                  <div class="text-danger" v-for="e in registerPasswordRepeatErrors" :key="e">{{ e }}</div>
                </div>
                <p v-if="registerError != null" class="alert alert-danger">
                  {{ registerError }}
                </p>
                <div class="alert alert-info" v-if="loading">
                  Məlumatlar yüklənir...
                </div>
                <div class="ps-form__submit" v-else>
                  <button class="ps-btn ps-btn--warning mr-4" type="submit">
                    Şifrəni dəyiş
                  </button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';
import {mapState} from "vuex";
import {setPassword} from "@/repositories/UserRepository";

export default {
  validations: {
    registerPassword: {required},
    registerPasswordRepeat: {required}
  },
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      loading: false,
      show1: false,
      show2: false,
      registerPassword: null,
      registerPasswordRepeat: null
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      token: state => state.auth.token,
      registerError: state => state.auth.registerError
    }),
    registerPasswordErrors() {
      const errors = [];
      this.v$.$errors.forEach(function (error) {
            if (error.$property === "registerPassword") {
              if (error.$validator === 'required') {
                errors.push('Şifrə vacib bölmədir');
              }
            }
          }
      )
      return errors;
    }
    ,
    registerPasswordRepeatErrors() {
      const errors = [];
      this.v$.$errors.forEach(function (error) {
            if (error.$property === "registerPasswordRepeat") {
              if (error.$validator === 'required') {
                errors.push('Şifrənin təkrarı vacib bölmədir');
              }
            }
          }
      )
      return errors;
    }
    ,
  },
  methods: {
    async handleLogin() {
      this.v$.registerPassword.$touch();
      this.v$.registerPasswordRepeat.$touch();
      if (!this.v$.$invalid && !this.loading) {

        if (this.registerPassword !== this.registerPasswordRepeat) {
          alert('Şifrələr eyni deyil');
          return;
        }

        this.loading = true;
        await setPassword(this.registerPassword, this.token).then(data => {
          this.loading = false;
          if (data.code === 200) {

            alert('Şifrəniz uğurla dəyişdirildi');


            let l = localStorage.getItem("goBackAfterLogin");
            localStorage.removeItem("goBackAfterLogin")
            if (!l) {
              l = '/';
            }
            window.location.href = l;

          } else {
            this.forgetError = data.message;
          }

        })

      }
    }
  }
}
;
</script>
