<template>
  <div style="padding-top: 100px;">
    <div class="ps-account">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-sm-6 ">

            <div class="alert alert-danger" v-if="forgetError">
              {{ forgetError}}
            </div>
            <div class="alert alert-info" v-else>
              Məlumat yüklənir
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {reset} from "@/repositories/UserRepository";
import cookie from "js-cookie";

export default {
  layout: 'default',
  data() {
    return {
      loading: false,
      forgetError: null,
      email: null,
      resetKey: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
  },
  methods: {
    async handleReset() {
        await reset(this.email, this.resetKey).then(data => {
          this.loading  = false;
          if (data.code === 200) {

            this.$store.dispatch('auth/setToken', data.token);
            cookie.set('token', data.token);
            this.$store.dispatch('loadUser',data.token);


            window.location.href = '/profile/password';

          } else if (data.code === 201) {
            this.forgetError = "Link 24 saat ərzində keçərlidir. Zəhmət olmasa yenidən şifrə yeniləmə sorğusu göndərin";
          }  else if (data.code === 402) {
            this.forgetError = "Bu link artıq istifadə olunub";
          } else if (data.code === 404) {
            this.forgetError = "Link yanlışdır. Zəhmət olmasa yenidən şifrə yeniləmə sorğusu göndərin";
          }  else if (data.code === 400) {
            this.forgetError = "Vacib məlumatlar daxil edilməyib";
          } else {
            this.forgetError = data.message;
          }

        })


    }
  },

  mounted() {

    this.email = this.$route.query.email||null;
    this.resetKey = this.$route.query.token||null;

    if(this.email && this.resetKey) {
      this.handleReset();
    } else {
      this.forgetError = "Link yanlışdır. Zəhmət olmasa yenidən şifrə yeniləmə sorğusu göndərin";
    }
  }
}
;
</script>
