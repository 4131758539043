<template>
    <div style="padding-top: 100px;">
        <div class="ps-contact">
            <div class="container">
                <breadcrumb :dataList="breadcrumb"/>
                <div class="ps-contact__content">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="ps-contact__info">
                                <h2 class="ps-contact__title">Sizə necə kömək edə bilərik?</h2>
                                <p class="ps-contact__text">Suallarınızı cavablandırmağa hazırıq!</p>
                                <h3 class="ps-contact__fax">{{settings.contactPhone}}</h3>
                                <div class="ps-contact__work" v-html="settings.workingHours">
                                </div>
                                <div class="ps-contact__email">
                                    <a :href="`mailto:${settings.contactEmail}`">{{settings.contactEmail}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="ps-contact__map" v-if="settings.addressText">

                                <GMapMap
                                        style="width: 100%; height: 400px"
                                        :center="mapCenter"
                                        :zoom="16"
                                        ref="searchMap"
                                >
                                    <GMapMarker :position="mapCenter"
                                                @click="isMarkerOpen = true"
                                                :icon="'/img/icon.png'">
                                        <GMapInfoWindow
                                                :closeclick="true"
                                                @closeclick="isMarkerOpen = false"
                                                :opened="isMarkerOpen">
                                            {{settings.addressText}}
                                        </GMapInfoWindow>
                                    </GMapMarker>
                                </GMapMap>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Breadcrumb from "../layout/page/Breadcrumb";
    import {mapState} from "vuex";

    export default {
        name: "ContactUs",
        components: {Breadcrumb},
        data() {
            return {
                isMarkerOpen: false
            }
        },
        computed: {
            ...mapState({
                settings: state => state.app.settings
            }),
            breadcrumb() {
                return [
                    {
                        url: '/',
                        text: 'Əsas səhifə'
                    },
                    {
                        url: '/contact',
                        text: 'Əlaqə'
                    },
                ];
            },
            mapCenter() {
                let p = this.settings.addressCoordinates.split(',')
                return {lat: parseFloat(p[0].trim()), lng: parseFloat(p[1].trim())};
            }
        }
    }
</script>

<style scoped>
    ::v-deep(.vue-map-container){
        height: 400px;
    }
</style>