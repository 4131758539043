<template>
    <div class="ps-product ps-product--list" v-if="product">
        <div class="ps-product__content">
            <div class="ps-product__thumbnail">
                <router-link
                        class="ps-product__image"
                        :to="`/product/${product.slug}`"
                        v-if="product.thumbnail"
                >
                    <figure>
                        <img :src="imagePath + product.thumbnail.url" alt="alt"/>
                        <img
                                :src="imagePath + product.thumbnail_back.url"
                                alt="alt"
                        /></figure
                    >
                </router-link>
                <div class="ps-product__actions">
                    <div
                            class="ps-product__item"
                            data-toggle="tooltip"
                            data-placement="left"
                            title="Quick view"
                    >
                        <a href="#" @click.prevent="productQuickview()"
                        ><i class="fa fa-search"></i
                        ></a>
                    </div>
                </div>
                <div class="ps-product__badge"></div>
            </div>
            <div class="ps-product__info">
                <router-link class="ps-product__branch" to="/">{{product.category.name}}</router-link>
                <h5 class="ps-product__title">
                    <router-link :to="`/product/${product.slug}`">{{ product.name }}</router-link>
                </h5>
                <div class="ps-product__rating">
                    <div class="d-inline-block">
                        <i v-for="i in 5" :key="i" class="fa mr-1 text-warning" :class="{'fa-star': product.rating>i-1, 'fa-star-o': product.rating<=i}"></i>
                        {{parseFloat(product.rating).toFixed(1)}}
                    </div>

                    <span class="ps-product__review">( {{product.rating_count}} Reviews)</span>
                </div>

                <div class="ps-product__desc" v-if="product.features !==''">
                    <ul class="ps-product__list">
                        <li v-for="f in product.features.split('\n')" :key="f">{{f}}</li>
                    </ul>
                </div>
                <div  v-if="product.badges.length">
                    <span
                            v-for="item in product.badges"
                            :key="item.id"
                            :class="[
                                'mr-2',
                        'ps-badge',
                        item.value === 'hot'
                            ? 'ps-badge--hot'
                            : item.value == 'new'
                            ? 'ps-badge--new'
                            : item.value == 'sale'
                            ? 'ps-badge--sale'
                            : 'ps-badge--sold'
                    ]"
                    >
                        {{ item.name }}
                    </span>
                </div>

            </div>
        </div>
        <div class="ps-product__footer">
            <div class="ps-product__meta">
                <span :class="[ 'ps-product__price', product.sale_price ? 'sale' : '' ]">
                    {{ product.sale_price ? product.sale_price : product.price }} AZN
                </span>
                <span class="ps-product__del" v-if="product.sale_price">{{ product.price }}AZN</span>
            </div>
            <div class="ps-product__quantity" v-if="product.is_stock">
                <h6>Miqdar</h6>
                <div class="def-number-input number-input safari_only">
                    <button class="minus" @click="quantity > 0 ? quantity-- : quantity">
                        <i class="icon-minus"></i>
                    </button>
                    <input
                            class="quantity"
                            min="0"
                            v-model="quantity"
                            type="number"
                            style="border: none"
                    />
                    <button class="plus" @click="quantity++">
                        <i class="icon-plus"></i>
                    </button>
                </div>
                <a class="ps-btn ps-btn--warning" href="#" @click.prevent="addToCart()">Səbətə əlavə et</a>
            </div>
            <div v-else class="my-4">
                <span class="ps-badge ps-badge--outstock">Anbarda yoxdur</span>
            </div>
            <!-- <div class="ps-product__variations">
                <a class="ps-product__link" href="#" @click.prevent="removeProductFromWishlist()" v-if="product.isFavorite">Seçilmişlərdən sil</a>
                <a class="ps-product__link" href="#" @click.prevent="addProductToWishlist()" v-else>Seçilmişlərə əlavə et</a>
            </div>-->
        </div>
    </div>
</template>

<script>
    import {baseUrl, imagePath} from "../../repositories/Repository";

    export default {
        props: {
            product: {
                type: Object
            }
        },
        data() {
            return {
                baseDomain: baseUrl,
                imagePath: imagePath,
                quantity: 1
            };
        },
        components: {},
        computed: {
            rating() {
                return Math.floor(Math.random() * Math.floor(6) + 3);
            }
        },
        methods: {
            addToCart() {
                const params = {
                    productId: this.product.id,
                    optionId: null,
                    count: this.quantity
                };
                this.$store.dispatch('cart/addProductToCart', params);
                this.$store.commit('cart/setDialogAddCart', true);
                this.$store.commit('cart/setProductOverview', this.product);

                this.$store.commit('cart/setDialogQuickview', false);
                this.$store.commit('cart/setDialogAddCart2', false);
            },
            productQuickview() {
                this.$store.commit('cart/setProductOverview', this.product);
                this.$store.commit('cart/setDialogQuickview', true);
                this.$store.commit('cart/setDialogAddCart', false);
                this.$store.commit('cart/setDialogAddCart2', false);
            },
            addProductToWishlist() {
                const params = {
                    id: this.product.id
                };
                const title = this.product ? this.product.name : '';
                const message = {
                    icon: 'icon-shield-check',
                    message: title + ' seçilmişlərə əlavə edildi!'
                }
                this.$store.dispatch('wishlist/addItemToWishlist', params);
                this.$store.commit('product/addWishlistItem', this.product);
                this.$store.commit('cart/setDialogAddCart2', false);
                this.$store.commit('app/setMessageNotify', message)
                this.$store.commit('app/setDialogNotify', true);
            },removeProductFromWishlist() {
                const params = {id: this.product.id};
                this.$store.dispatch('wishlist/removeItemFromWishlist', params);
            }
        }
    }
</script>
