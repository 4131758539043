<template lang="html">
    <div class="ps-navigation--footer">

        <div class="ps-nav__item">
            <a :href="`tel:${settings.callNumber}`"><i class="fa fa-phone"></i></a>
        </div>
        <div class="ps-nav__item">
            <a :href="`https://wa.me/${settings.whatsappNumber}`" target="_blank">
              <i class="fa fa-whatsapp"></i>
            </a>
        </div>
        <div class="ps-nav__item">
            <router-link to="/cart">
                <i class="icon-cart-empty"></i>
                <span class="badge">{{ productCartQuantity }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'NavigationBottom',
        computed: {
            ...mapState({
                appDrawer: state => state.app.appDrawer,
                currentDrawerContent: state => state.app.currentDrawerContent,
                cartItems: state => state.cart.cartItems,
                wishlistItems: state => state.wishlist.items,

              settings: state => state.app.settings,
            }),
            productCartQuantity() {
                let quantity = 0;
                this.cartItems.forEach(element => {
                    quantity += element.count;
                });
                return quantity;
            }
        },
        data() {
            return {
                drawer: true
            };
        },
        methods: {
        }
    };
</script>

<style lang="scss" scoped></style>
