<template>
  <div class="ps-login--modal">
    <form>
      <div class="form-group">
        <label>E-mail</label>
        <input class="form-control" type="text" v-model="email">
      </div>
      <div class="form-group">
        <label>Şifrə</label>
        <input class="form-control" type="password" v-model="password">

        <div class="text-center small">
          <router-link class="ps-account__link" to="/forget">
            <u>Şifrəmi unutmuşam</u>
          </router-link>
        </div>
      </div>
      <p v-if="loginError != null" class="alert alert-danger">
        {{ loginError }}
      </p>
      <button class="ps-btn ps-btn--warning" @click.prevent="handleLogin()">Giriş</button>
      <br>
      <div class="text-center mt-2">
        <a class="text-primary" href="/register">Qeydiyyat</a>
      </div>
    </form>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'login-modal',
  data() {
    return {
      email: null,
      password: null,
    }
  },
  computed: {
    ...mapState({
      loginError: state => state.auth.loginError,
      user: state => state.auth.user,
    })
  },
  methods: {
    handleLogin() {
      this.$store.commit('app/setLoading', true);
      this.$store.dispatch('auth/login', {email: this.email, password: this.password});
      this.$store.commit('app/setLoading', false);
    }
  },
}
</script>
