<template>
  <div class="ps-blog--latset">
    <div class="ps-blog__thumbnail embed-responsive embed-responsive-16by9">
      <router-link class="embed-responsive-item" :to="{name: 'blog', params:{slug: blog.slug }}">
        <img :src="imageWidePath + blog.banner" :alt="blog.title">
      </router-link>
    </div>
    <div class="ps-blog__content">
      <div class="ps-blog__meta">
        <span class="ps-blog__date">{{ getDate(blog.postTime.date) }}</span>
        <a class="ps-blog__author">Solart.az</a>
      </div>
      <router-link
          :to="{name: 'blog', params:{slug: blog.slug }}"
          class="ps-blog__title text-left font-weight-bold">
        {{ blog.title }}
      </router-link>
<!--
      <p class="ps-blog__desc">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
        eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes</p>-->
    </div>
  </div>
</template>
<script>
import {imageWidePath} from "@/repositories/Repository";

export default {
  name: 'BlogPost',
  props: {
    blog: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      imageWidePath: imageWidePath,
    }
  },

  methods:{
    getDate(date) {
      return new Date(date).toLocaleDateString();
    }
  }
}
</script>
<style scoped>

.ps-blog__title {
  color: #103178;
  text-align: center;
}
</style>