<template>
    <div>
        <div class="ps-shopping">
            <div class="container" v-if="orderDetails!== null">
                <h3 class="ps-shopping__title">Sifariş #{{orderDetails.id}} </h3>
                <div class="ps-shopping__content">
                    <div class="row">
                        <div class="col-12  col-lg-9">

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card mb-4">

                                        <div class="card-header">Çatdırılma</div>
                                        <div class="card-body" v-if="editShippingType">
                                            <form @submit.prevent="updateShippingInfo()">
                                                <div class="mb-3">
                                                    <label>Çatdırılma: </label>
                                                    <select class="form-control" v-model="orderDetails.shippingType">
                                                        <option value="take">Özüm götürəcəm</option>
                                                        <option value="delivery">Çatdırılma</option>
                                                    </select>
                                                </div>

                                                <div class="mb-3" v-if="orderDetails.shippingType==='take'">
                                                    <label>Filialı seçin</label>
                                                    <select class="form-control" v-model="deliveryBranch">
                                                        <option v-for="b in branches" :value="b.id" :key="b.id">
                                                            {{b.name}}
                                                        </option>
                                                    </select>

                                                    <div class="my-3" v-if="orderDetails.branch !== null">
                                                        <button type="submit" class="ps-btn ps-btn--warning">
                                                            Çatdırılma ayarlarını saxla
                                                        </button>
                                                        <div class="text-center mt-2">
                                                            <a class="ps-shopping__link"
                                                               @click.prevent="editShippingType=false">
                                                                ləğv et
                                                            </a>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="mb-3" v-else-if="orderDetails.shippingType==='delivery'">
                                                    <label>Çatdırılma ünvanını seçin</label>
                                                    <select class="form-control" v-model="deliveryAddress">
                                                        <option v-for="a in addresses" :value="a.id" :key="a.id">
                                                            {{a.name}}
                                                        </option>
                                                    </select>


                                                    <a class="ps-shopping__link" @click.prevent="addAddressOpen=!addAddressOpen">
                                                        yeni ünvan əlavə et
                                                    </a>

                                                    <div class="my-3" v-if="addAddressOpen">
                                                        <hr>
                                                        <add-addess-form @addressCreated="addressCreated"></add-addess-form>
                                                    </div>

                                                    <div class="my-3" v-if="orderDetails.address !== null">
                                                        <button type="submit" class="ps-btn ps-btn--warning">
                                                            Çatdırılma ayarlarını saxla
                                                        </button>
                                                        <div class="text-center mt-2">
                                                            <a class="ps-shopping__link"
                                                               @click.prevent="editShippingType=false">
                                                                ləğv et
                                                            </a>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div v-else>
                                                    <div class="alert alert-info">
                                                        Zəhmət olmasa çatdırılma növünü seçin
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                        <div class="card-body" v-else>
                                            <div class="mb-3">
                                                <strong>Çatdırılma: </strong>
                                                <span v-if="orderDetails.shippingType==='take'">Özüm götürəcəm</span>
                                                <span v-else-if="orderDetails.shippingType==='delivery'">Çatdırılma</span>
                                                <span v-else>Zəhmət olmasa seçin</span>
                                            </div>

                                            <div class="mb-3" v-if="orderDetails.shippingType==='take'">
                                                <div>
                                                    <strong>{{orderDetails.branch.name}}</strong> <br>
                                                    <span>{{orderDetails.branch.address}}</span> <br>
                                                    <span v-html="orderDetails.branch.schedule"></span>
                                                </div>
                                            </div>
                                            <div class="mb-3" v-else-if="orderDetails.shippingType==='delivery'">
                                                <strong>{{orderDetails.address.name}}</strong> <br>
                                                <strong>{{orderDetails.address.district.city.name}}, {{orderDetails.address.district.name}}</strong> <br>
                                                <span>{{orderDetails.address.address}}</span> <br>

                                            </div>

                                            <div v-else>
                                                <div class="alert alert-info">
                                                    Zəhmət olmasa çatdırılma növünü seçin
                                                </div>
                                            </div>

                                            <div class="my-3"
                                                 v-if="['new','confirmed','waiting_payment'].indexOf(orderDetails.orderStatus)>-1">
                                                <a class="ps-shopping__link"
                                                   @click.prevent="editShippingType=true">
                                                    Çatdırılma ayarlarını dəyiş
                                                </a>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                                <div class="col-md-6">

                                    <div class="card mb-3">

                                        <div class="card-header">Ödəniş</div>
                                        <div class="card-body" v-if="editPaymentType">
                                            <form @submit.prevent="updatePaymentInfo()">
                                                <div class="mb-3">
                                                    <label>Ödəniş metodu: </label>
                                                    <select class="form-control" v-model="orderDetails.paymentType">
                                                        <option value="cash">Çatdırılma zamanı nağd</option>
                                                        <option value="card">Online</option>
                                                    </select>
                                                </div>


                                                <div class="my-3" v-if="orderDetails.paymentType !== 'none'">
                                                    <button type="submit" class="ps-btn ps-btn--warning">
                                                        Ödəmə üsulunu təsdiqlə
                                                    </button>

                                                    <div class="text-center mt-2">
                                                        <a class="ps-shopping__link" href="#"
                                                           @click.prevent="editPaymentType=false">
                                                            ləğv et
                                                        </a>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="card-body" v-else>
                                            <div class="mb-3">
                                                <strong>Ödəniş metodu: </strong>
                                                <span v-if="orderDetails.paymentType==='cash'">Çatdırılma zamanı nağd</span>
                                                <span v-else-if="orderDetails.paymentType==='card'">Online</span>
                                                <span v-else>Zəhmət olmasa seçin</span>
                                            </div>

                                            <div class="my-3"
                                                 v-if="['new','confirmed','waiting_payment'].indexOf(orderDetails.orderStatus)>-1">
                                                <a class="ps-shopping__link" href="#"
                                                   @click.prevent="editPaymentType=true">
                                                    Ödəniş ayarlarını dəyiş
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="card mb-4">

                                <div class="card-header">Məhsullar</div>
                                <div class="card-body">

                                    <table class="table ps-table ps-table--product">
                                        <thead>
                                        <tr>
                                            <th class="ps-product__thumbnail"></th>
                                            <th class="ps-product__name">Məhsul</th>
                                            <th class="ps-product__meta">Qiymət</th>
                                            <th class="ps-product__quantity">Miqdar</th>
                                            <th class="ps-product__subtotal">Cəmi</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(orderItem, index) in orderItems" :key="index">
                                            <td class="ps-product__thumbnail">
                                                <router-link class="ps-product__image"
                                                             :to="`/product/${orderItem.product.slug}`"
                                                             v-if="orderItem.product.thumbnail">
                                                    <figure><img :src="baseDomain + orderItem.product.thumbnail.url"
                                                                 alt>
                                                    </figure>
                                                </router-link>
                                            </td>
                                            <td class="ps-product__name">
                                                <router-link :to="`/product/${orderItem.product.slug}`">
                                                    {{orderItem.product.name }}
                                                </router-link>
                                                <br>
                                                <a class="mx-3 btn btn-sm btn-warning"
                                                   @click="openRateModal(orderItem.product)"
                                                   v-if="orderDetails.orderStatus==='finished'">
                                                    dəyərləndir
                                                </a>

                                            </td>
                                            <td class="ps-product__meta">
                                            <span :class="[ 'ps-product__price',orderItem.product.sale_price ? 'sale' : '']">
                                                {{
                                                    orderItem.product.sale_price ? orderItem.product.sale_price : orderItem.product.price
                                                }} AZN
                                            </span>
                                                <span class="ps-product__del" v-if="orderItem.product.sale_price">
                                            {{ orderItem.product.price }} AZN
                                        </span>
                                            </td>
                                            <td class="ps-product__meta text-center">
                                                <span>{{ orderItem.count }} </span>
                                            </td>
                                            <td class="ps-product__meta">
                                                <span>{{ orderItem.price }} AZN </span>
                                            </td>
                                        </tr>
                                        <tr v-if="!orderItems || !orderItems.length">
                                            <td colspan="6">Yüklənir...</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>

                        </div>
                        <div class="col-12 col-lg-3">

                            <div>

                                <div class="alert alert-info" v-if="orderDetails.orderStatus==='new'">
                                    Yeni sifariş
                                </div>
                                <div class="alert alert-success" v-else-if="orderDetails.orderStatus==='confirmed'">
                                    Təsdiqlənmiş sifariş
                                </div>
                                <div class="alert alert-warning"
                                     v-else-if="orderDetails.orderStatus==='waiting_payment'">
                                    Online ödəniş tələb olunur
                                </div>
                                <div class="alert alert-success" v-else-if="orderDetails.orderStatus==='paid'">
                                    Online ödəniş qəbul edilib. Sifarişiniz işlənilir
                                </div>
                                <div class="alert alert-success" v-else-if="orderDetails.orderStatus==='preparing'">
                                    Paketiniz hazırlanır
                                </div>
                                <div class="alert alert-success" v-else-if="orderDetails.orderStatus==='delivering'">
                                    Paketiniz yoldadır
                                </div>
                                <div class="alert alert-success" v-else-if="orderDetails.orderStatus==='finished'">
                                    Sifarişiniz tamalandı
                                </div>
                                <div class="alert alert-success" v-else>
                                    {{orderDetails.orderStatus}}
                                </div>

                            </div>

                            <div class="ps-shopping__box">
                                <div class="ps-shopping__row">
                                    <div class="ps-shopping__label">Cəmi</div>
                                    <div class="ps-shopping__price">{{ orderDetails.totalPrice }} AZN</div>
                                </div>
                                <div class="ps-shopping__row" v-if="orderDetails.personalDiscountPercent>0">
                                    <div class="ps-shopping__label">Şəxsi endirim</div>
                                    <div class="ps-shopping__price">{{ orderDetails.personalDiscountAmount }} AZN
                                        ({{orderDetails.personalDiscountPercent}} %)
                                    </div>
                                </div>
                                <div class="ps-shopping__row">
                                    <div class="ps-shopping__label">Çatdırılma</div>
                                    <div class="ps-shopping__price">{{ orderDetails.deliveryPrice }} AZN</div>
                                </div>
                                <div class="ps-shopping__row">
                                    <div class="ps-shopping__label">Ödəniləcək</div>
                                    <div class="ps-shopping__price">{{ orderDetails.price }} AZN</div>
                                </div>
                                <div class="ps-shopping__checkout">
                                    <a href="#" class="ps-btn ps-btn--warning" @click.prevent="payOrder()"
                                       v-if="orderDetails.paymentType==='card' && orderDetails.orderStatus === 'waiting_payment'">
                                        Ödənişə davam et
                                    </a>
                                    <router-link class="ps-shopping__link" to="/products">
                                        Alış-verişə davam et
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" v-else>
                <div class="alert alert-info">
                    Loading...
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {baseUrl} from '../../repositories/Repository';
    import {
        getAddressList,
        getOrder,
        payOrder,
        setOrderShippingInfo,
        setPaymentInfo
    } from "../../repositories/UserRepository";
    import {getBranches} from "../../repositories/GeneralDataRepository";

    import AddAddessForm from "../layout/AddAddessForm";

    export default {
        layout: 'default',
        components: {AddAddessForm},
        data() {
            return {

                baseDomain: baseUrl,
                orderDetails: null,
                orderItems: [],
                editShippingType: false,
                branches: [],
                addresses: [],
                editPaymentType: false,
                addAddressOpen: false
            }
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
                token: state => state.auth.token,
            }),
            orderId() {
                return this.$route.params.id;
            },
            breadcrumb() {
                return [
                    {
                        url: '/',
                        text: 'Əsas səhifə'
                    },
                    {
                        url: '/profile/orders',
                        text: 'Sifarişlər'
                    },
                    {
                        url: '/profile/order/' + this.orderDetails.id,
                        extraClass: 'active',
                        text: 'Sifariş #' + this.orderDetails.id
                    }
                ];
            },
            deliveryBranch: {
                get() {
                    if (this.orderDetails.branch == null) {
                        return null
                    } else {
                        return this.orderDetails.branch.id;
                    }
                },
                set(id) {
                    this.orderDetails.branch = {id: id};
                }
            },
            deliveryAddress: {
                get() {
                    if (this.orderDetails.address == null) {
                        return null
                    } else {
                        return this.orderDetails.address.id;
                    }
                },
                set(id) {
                    this.orderDetails.address = {id: id};
                }
            },
        },
        async created() {

            this.loadOrder();

            this.loadAddressList();

            getBranches('az').then((branches) => {
                this.branches = branches;
            })


            this.$store.commit('app/setLoading', false);
        },
        methods: {
            loadOrder() {
                getOrder(this.token, this.orderId).then((data) => {
                    if (data !== null) {
                        this.orderDetails = data.order;
                        this.orderItems = data.items;
                        if (this.orderDetails.shippingType === 'none') {
                            this.editShippingType = true;
                        }
                        if (this.orderDetails.paymentType === 'none') {
                            this.editPaymentType = true;
                        }
                    } else {
                        this.orderDetails = null;
                        this.orderItems = [];
                    }
                })
            },
            handleCoupon() {
                console.log('couponCode', this.couponCode);
            },
            updateShippingInfo() {

                setOrderShippingInfo(
                    this.token, this.orderDetails.id, this.orderDetails.shippingType,
                    this.deliveryBranch, this.deliveryAddress, ''
                ).then(() => {
                    this.editShippingType = false;
                    this.loadOrder();
                });

            },
            updatePaymentInfo() {

                setPaymentInfo(
                    this.token, this.orderDetails.id, this.orderDetails.paymentType
                ).then(() => {
                    this.editPaymentType = false;
                    this.loadOrder();
                });

            },
            payOrder() {

                payOrder(
                    this.token, this.orderDetails.id
                ).then((data) => {
                    if (data === null) {
                        alert('Əməliyyatı icra etmək mümkün olmadı, zəhmət olmasa bir az sonra təkrar cəhd edin')
                    } else {
                        window.location.href = data;
                    }
                });

            },

            openRateModal(product) {
                this.$store.commit('cart/setDialogRating', true);
                this.$store.commit('cart/setProductOverview', product);
            },
            loadAddressList() {
                getAddressList(this.token).then((addresses) => {
                    this.addresses = addresses;
                })
            },
            addressCreated(addressId) {
                this.loadAddressList();
                this.deliveryAddress = addressId;
                this.addAddressOpen = false;
            }
        }
    }
</script>
